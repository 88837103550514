import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../styles/OrderSuccessPage.css"
import toast, { Toaster } from 'react-hot-toast';
import Spacer from "../components/Spacer";
import { currencyFormat } from "../utilities/Utilities";



export default function OrderSuccessPage(){
    const {state} = useLocation();
    const navigate = useNavigate();


    let clabe = "058597000013299509"
    let accountNumber = "995149060016"
    let cardNumber = "4741745775954650"
    let bankName = "BanRegio"
    let accountHolder = "Sergio Andrés Tapia Duarte"

    const navigateToHome = () => {
        navigate('/tickets')
    }
    if (state.tickets[0].ticketType === "photographer"){
        return(
            <div>
            <p>Gracias por apartar boleto. Dentro de 72 horas, revisarémos tu petición y recibirás tu boleto.</p>
            <button onClick = {navigateToHome}>
            Regresar al inicio
        </button>
        </div>
        )
    }
   
    async function copyToClipboard(text){
        await navigator.clipboard.writeText(text)
        toast.success('Copiado al portapapeles.');
    }


    return(
        <section>
            <Toaster
            position="top-center"
            reverseOrder={false}
            />
            <p>Gracias por apartar boleto(s). Dentro de 72 horas, por favor realiza una transferencia con los datos abajo: </p>
            <div id="snackbar">Copiado al portapapeles</div>

            <p>A nombre de: </p>
            <p>{accountHolder}</p>
            <button onClick = {() => {copyToClipboard(accountHolder)}}>
            Copiar
            </button>

            <p>Monto: </p>
            <p>{currencyFormat(state.order.amountDue)}</p>
            <button onClick = {() => {copyToClipboard(state.order.amountDue)}}>
            Copiar
            </button>

            <p>Banco: </p>
            <p>{bankName}</p>
            <button onClick = {() => {copyToClipboard(bankName)}}>
            Copiar
            </button>

            <p> Numero de cuenta: </p>
            <p>{accountNumber}</p>
            <button onClick = {() => {copyToClipboard(accountNumber)}}>
            Copiar
            </button>

            <p> Numero de tarjeta: </p>
            <p>{cardNumber}</p>
            <button onClick = {() => {copyToClipboard(cardNumber)}}>
            Copiar
            </button>

            <p>CLABE:</p>
            <p>{clabe}</p>
            <button onClick = {() => {copyToClipboard(clabe)}}>
            Copiar
            </button>

            <p>Concepto / Referencia :</p>
            <p>{state.order.confirmationNumber}</p>
            <button onClick = {() => {copyToClipboard(state.order.confirmationNumber)}}>
            Copiar
            </button>
            <br/>
            <button onClick = {navigateToHome}>
                Regresar al inicio
            </button>
            <Spacer/>   
            <Spacer/>  


        </section>
    );
}