 // months are indexed at 0 in javascript

// EARLY BIRD - OCTOBER 32
let phase1Deadline = new Date(2023, 10, 1);

// PHASE 2 - NOVEMBER 8
let phase2Deadline = new Date(2023, 10, 9);

//PHASE 3 - NOVEMBER 16
let phase3Deadline = new Date(2023, 10, 17);

export function getTicketSalePhase() {
    let currentDate = new Date()
    if(currentDate < phase1Deadline){
        return("Early Bird Fase 1");
    } else if (currentDate < phase2Deadline){
        return("Early Bird Fase 2");
    } else if (currentDate < phase3Deadline){
        return("Early Bird Fase 3");
    } else {
        return("Admisión General")
    }
}

export function calculatePrice(ticketType){
    var price = 300.0
    let currentDate = new Date()
    // General Entry
    if (ticketType === 'spectator'){
        if(currentDate < phase1Deadline){
            price = 150.0;
        } else if (currentDate < phase2Deadline){
            price = 200.0;
        } else if (currentDate < phase3Deadline){
            price = 250.0;
        }
    } else if (ticketType === 'participant' ) {
        if(currentDate < phase1Deadline){
            price = 100.0;
        } else if (currentDate < phase2Deadline){
            price = 150.0;
        } else if (currentDate < phase3Deadline){
            price = 200.0;
        } else {
            price = 250.0;
        }
    } else if (ticketType === 'photographer'){
        price = 0.0;
    }
    return price;
}

export function currencyFormat(num) {
    if(num == null){
        return "$0.00"
    }
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + " MXN"
 }

 export function generateOrderReferenceCode(){
    const length = 10;
    const chars =  '23456789ABCDEFGHJKLMNPQRSTUVWXYZ';
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
    return result;    
}

export function generateUUID() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }