import React from "react";
import { useState } from "react";
import toast, { Toaster } from 'react-hot-toast';
import {createPromoCode } from '../utilities/FirebaseFunctions'

export default function PromoCodeCreator() {
    const defaultExpDate = new Date("2023-11-18");
    const [promoString, setPromoString ] = useState("");
    const [discountAmount, setDiscountAmount] = useState(0.15);
    const [expirationDate, setExpirationDate] = useState(defaultExpDate);
    const [allowedNumberOfUses, setAllowedNumberOfUses] = useState(50);

    async function handleCreatePromoCode(){
        await createPromoCode(promoString, discountAmount, allowedNumberOfUses, expirationDate)
        toast.success('¡Código promo creado exitosamente!')
    }

    return (
        <div>
            <Toaster
            position="top-center"
            reverseOrder={false}
            />
            <form>

            <label>código promo: <br/>
        <input type="text"
         value={promoString}
         onChange={(e) => setPromoString(e.target.value)} 
         />
         <br/>
        </label>

        <label>monto de descuento en pesos :<br/>
        <input type="number" 
        value={discountAmount}
        onChange={(e) => setDiscountAmount(e.target.value)}
        />
        <br/>

        </label>

        <label> numero de usos :<br/>
        <input type="number" 
        value={allowedNumberOfUses}
        onChange={(e) => setAllowedNumberOfUses(e.target.value)}
        />
        <br/>

        </label>

        <label>fecha de expiración :<br/>
        <input type="date" 
        value={expirationDate}
        onChange={(e) => setExpirationDate(e.target.value)}
        />
        <br/>
        </label>


                
                </form>

                <button onClick={handleCreatePromoCode}>
            Crear Promo
        </button>

        </div>

    );


}