import React from "react";
import { useState } from "react";
import { SpectatorForm } from "../components/SpectatorForm";
import PhotographerForm from "../components/PhotographerForm";
import ParticipantForm from "../components/ParticipantForm";
import { useNavigate } from 'react-router-dom';


   export default function BuyTicketsPage(){
    const [ticketType, setTicketType] = useState("spectator");
    const navigate = useNavigate();

    const navigateToHome = () => {
        navigate('/')
    }

    return(
        <section>
        <h1>Apartar Boletos</h1>
        {/* <p className = "fine-text">Para apartar boletos, es necesario tener una cuenta. Si aún no tienes una cuenta, puedes abrir una <u><a href = '#' onClick = {()=> navigate('/login')}>aquí</a></u>.</p>
        <label>Elige tu tipo de boleto:</label>
        <select name="ticketType" id="ticketType" onChange={(e) => setTicketType(e.target.value)} >
        <option value="spectator">Espectadorx</option>
        <option value="participant">Participantx</option>
        <option value="photographer">Fotografx</option>
        </select>
    {ticketType ==="spectator" ? (<SpectatorForm/>) : ticketType === "participant" ? (<ParticipantForm/>) : ticketType ==="photographer" ? (<PhotographerForm/>) : <div></div> }
        </section> */}
        <p>Ya hemos cerrado nuestra preventa de boletos.</p>
        <p>En taquilla podrás realizar la compra de boletos (solo efectivo). </p>
        <button onClick = {navigateToHome}>
            Regresar al inicio
        </button>
        </section>
    );
} 

