import React from "react";
import { useLocation } from "react-router-dom";
import { viewAllTicketsForOrder } from "../utilities/FirebaseFunctions";
import { useState , useEffect} from "react";
import QRCode from "react-qr-code";
import title from '../assets/MAGDALENA-TITLE.png'
import '../styles/ViewTicketsPage.css'
import Spacer from "../components/Spacer";




export default function ViewTicketsPage(){
    const {state} = useLocation();
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);



    useEffect(()=> {
        async function fetchTicketData() {
          console.log("STATE: ", state)
          setIsLoading(true);
          try {
            const response = await viewAllTicketsForOrder(state.order.confirmationNumber)
            setData(response);
          } catch (error) {
            console.error(error);
         } finally {
            setIsLoading(false);
         }
          }
          fetchTicketData();
        }, []);
    
        if (isLoading) {
          return <div style={{ color: 'black' }}>Loading...</div>;
        }
        if (data) {
          if (data === []){
            return <p>Al momento aún no tienes boletos.</p>;
        }
          return <section>

            <div className='outer-ring'>
            <div className='middle-ring'>
            <div className='inner-ring'>


            <div class="scrollmenu">

                      {data.map(item => (<div className = 'ticket-view' key={item.id}>
                        <div>
                        <img className="title-image" alt="House of Magdalena" src={title}/> 
                        <div className="met-gala-title">
                        <h2>Met Gala 2023</h2>
                        </div>

                        <h2>Manus x Machina</h2>
                        

                        <div>
                        <h3>Entrada</h3>
                        <h2>{state.order.alias} {item.houseName}</h2>
                       {item.ticketType === "participant" ? <h2>Participantx</h2> : item.ticketType === "spectator" ? <h2>Espectadorx</h2> : <h2>Fotógrafx</h2>}
                        </div> 
                        <div className = "qr-container">
                        <QRCode
                        size={256}
                        style={{ height: "auto", maxWidth: "80%", width: "80%" }}
                        value={item.ticketID}
                        viewBox={`0 0 256 256`}
                        />
                        </div> 
                        </div>

                        <div className = 'event-info'>
                        <p>17 de noviembre 2023</p>
                        <p>Milan 41</p>
                        <p>Colonia Juárez</p>
                        <p>Ciudad de México</p>
                        <p>18:00</p>
                        </div>
                         
                    </div>
                      
                      ))}
              </div>
            </div>
            </div>
            </div>
            <Spacer/>
            <Spacer/>


        </section>
    }
     else {
        return <div style={{ color: 'black' }}>Sin data</div>;

     }
}