import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/HomePage.css';
import sample from '../assets/MetGalaBackgroundVideo.mp4';
import title from '../assets/MAGDALENA-TITLE.png'
import Spacer from '../components/Spacer';
import star from '../assets/starImage.png'
import vans from '../assets/VANS-VERDE.png'
function HomePage() {
  const navigate = useNavigate();

  function proceedToLogin() {
    navigate('/login')
  }
  function navigateToBuyTickets(){
    navigate("/tickets")
}

  return(
  <div className="page_div">
  <div className="canvas_div">
  <div className="bg-vid-container">

  <video id="bg-vid" className='videoTag' playsInline autoPlay loop muted>
    <source src={sample} type='video/mp4' />
</video>
</div> 
  <div id="overlay">
   <img className="title-image" src={title}/> 
  <p  className="presents"> presenta: </p>
   
<div className='manus-container'>
    <img className="spinning-star" src={star}/> 
    <p className="manus" >Manus</p> 
    <img className="spinning-star" src={star}/> 
    </div>
    <p className = "x">X</p> 
    <p className = "machina">MACHINA</p>
    <div className="met-gala-title">
    <h2>Met Gala 2023</h2>
    <p>17 de noviembre 2023</p>
        <p>Milan 41</p>
        <p>Colonia Juárez</p>
        <p>Ciudad de México</p>
        <p>18:00</p>
    </div>
    <div className="met-gala-title">

    <h3>Ya hemos cerrado nuestra preventa de boletos.</h3>
        <h4>En taquilla podrás realizar la compra de boletos (solo efectivo). </h4>
</div>
    {/* <button onClick = {()=>{}} >Categorias</button> */}
    <button onClick={navigateToBuyTickets}>Boletos</button>
    {/* <button onClick = {proceedToLogin} >Ver Boletos</button> */}
    <div className = 'sponsors'>
      <p>patrocinado por: </p>
      <img alt = 'Vans Logo' className = 'vans-logo' src = {vans}/>
    </div>
    <Spacer/> 
    <Spacer/>

  </div>  
</div>
</div>


     );
}

export default HomePage;
