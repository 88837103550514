import React from "react";
import { useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";


export default function EmailLoginPage(){
    const auth = getAuth();
    const navigate = useNavigate();

   

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    function handleSignin(){
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            navigate('/tickets')
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            toast.error(errorMessage);
          });
    }

    function goToNewAccountPage(){
        navigate('/createNewAccount');
    }
    function handleResetPassword(){
        navigate('/resetPassword');
    }

    return (
        <section>
            <Toaster/> 
            <p> Ingresa a tu cuenta: </p>
            <button onClick={goToNewAccountPage} >Crear una cuenta</button>
            <form>
            <label> correo electrónico: <br/>
        <input type="text"
         value={email}
         onChange={(e) => setEmail(e.target.value)} 
         />
         <br/>
        </label>
        <label> contraseña:<br/>
        <input type="password"
         value={password}
         onChange={(e) => setPassword(e.target.value)} 
         />
         <br/>
        </label>
        
        </form>
        <button onClick={handleResetPassword}> ¿Olvidaste tu contraseña? </button>
            <button onClick = {handleSignin}>Ingresar</button>
        </section>
    )

}