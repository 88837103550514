import { startAfter } from "firebase/firestore";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { createPendingTicket, createPendingOrder, sendOrderConfirmation } from '../utilities/FirebaseFunctions'
import { currencyFormat } from "../utilities/Utilities";
import Spacer from "../components/Spacer";
import { useAuth } from "../utilities/useAuth";


export default function OrderConfirmationPage(){
    const { user } = useAuth();
    const {state} = useLocation();
    const navigate = useNavigate();

    if (!user){
        navigate("/login")
    }

    async function handleConfirmationPressed(){
        await createPendingOrder(state.order)
        await state.tickets.forEach((x) => createPendingTicket(x))
        await sendOrderConfirmation(state.order)
        navigate('/orderSuccessPage', {state:{order: state.order, tickets: state.tickets}})
    }

    return(
        <section>
             <h1>Resumen de compra:</h1>

            <h2>Confirma tu pedido</h2>
            <p>A nombre de: </p>
            <p>{state.order.alias} {state.tickets[0].houseName}</p>

            <p>Email: </p>
            <p>{state.order.email}</p>

            <p>Monto: </p>
            <p>{currencyFormat(state.order.amountDue)}</p>

            <p className="fine-text">* Los pagos se realizan a través de una transferencia bancaria. Si no cuentas con una cuenta bancaria, favor de hablarnos por WhatsApp o Instgram.</p>

           
           <button onClick={handleConfirmationPressed}>
            Aparta mi(s) boleto(s)
           </button>
           <Spacer/> 

        </section>

    );
}