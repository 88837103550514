import React, { useEffect, useState } from 'react';
import { app } from '../Firebase'
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import { ticketConverter } from '../utilities/FirebaseFunctions';
import {QrScanner} from '@yudiel/react-qr-scanner';
import "../styles/CodeReaderPage.css"
import Spacer from '../components/Spacer';
import useSound from 'use-sound';
import successSound from '../assets/success.mp3';
import errorSound from '../assets/error.mp3';


const db = getFirestore(app);

export default function CodeReaderPage(){

  const [data, setData] = useState("");
  const [screenUnlocked, setScreenUnlocked] = useState(false);

 // var previousValue = ""; 

  // useEffect(()=> {
  //   function determineSetData(){
  //     console.log("USEEFFECT TRIGGERED: ", data);
  //       if (data.length > 2 && !data.includes("/")){
  //         newInput = true;
  //       } else {
  //         newInput = false;
  //       }
  //   }
  //   determineSetData();
  //   }, [data]);   


  function closeView(){
      //previousValue = data;
      setData(""); 
  }

  function changeLockScreenState(){
    setScreenUnlocked(true); 
  }

  function setQRData(data){
    setData(data); 
  }

  if(screenUnlocked === false){
    return(
          <LockScreen changeLockScreenState = {changeLockScreenState}/>
    );
  }

  if(data !== "" ){
    console.log("DATA: ", data);
    return (
      <LoadingScreen data = {data} closeView = {closeView}/>
    ); 
  }

  return (
    <QRReaderView qrData = {data} setQRData={setQRData}/>
  ); 


function LockScreen({changeLockScreenState}){
  const [password, setPassword] = useState("");
  function handleSignin(){
    if(password === 'MAGGIES2023'){
      changeLockScreenState()
    } else {
      setPassword('');
      alert("contraseña incorrecta");
    }
  }

  return (

    <section>
      <h1>Ingresa la contraseña para verificar códigos:</h1>
      <form>
      <input type="password"
         value={password}
         onChange={(e) => setPassword(e.target.value)} 
         />
      </form>
      <button onClick = {handleSignin}>Ingresar</button>

    </section>
  );
}


function QRReaderView({qrData, setQRData}){
const [delayScan , setDelayScan] = useState(500);


const handleScan = (result) => {
    console.log("Result",result);
  if (!!result) {
    setQRData(result);
  }

  // if (!!error) {
  //   //console.info(error);
  // }
};



return (
  <section>
  {qrData === "" ? (
    <QrScanner className = "view-finder"
      constraints={{facingMode: 'environment', width:{min: 640, ideal: 720, max: 1920 }, height: {min: 640, ideal: 720, max: 1920}}}
      scanDelay={delayScan}
      onDecode={(result) => handleScan(result)}
      onError={(error)=> console.log("Error: ", error)}
      videoStyle={{top:"48px"}}
      hideCount ={false}
    />
  ) : null}
  <p>{qrData}</p>
</section>
);
}

function LoadingScreen({data, closeView }){
  const [loadingScreenState, setLoadingState] = useState('loading');
  const [codeData, setCodeData] = useState("");
  const [playSuccess, { stopSuccess }] = useSound(
    successSound,
    { volume: 0.5 }
  );

  const [playError, { stopError }] = useSound(
    errorSound,
    { volume: 0.5 }
  );


  function handleCloseView(){
    setCodeData("");
    closeView()
  }


  useEffect(()=> {
    async function validateQRCode(data){
      // if(data.length < 3) {
      //   return
      // }
      const docRef = doc(db, 'tickets', data).withConverter(ticketConverter);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const ticketInfo = docSnap.data()
        if (ticketInfo.status === "approved"){
          const welcomeMessage = ticketInfo.ticketType + " " + ticketInfo.houseName;
          await updateDoc(docRef, {
            status: "used"
          });
          setCodeData(welcomeMessage)
          setLoadingState('success');
          return
        }
        else if (ticketInfo.status === "used"){
          setCodeData("Este boleto ya ha sido usado:  " + data)
          setLoadingState("error")
          return
        }
        else if (ticketInfo.status === "pending"){
          setCodeData("Este boleto aun no ha sido pagado:" + data)
          setLoadingState("error")
          return
        }
    } else {
        setCodeData("Este código no es válido: " + data)
          setLoadingState("error")
          playError()
          return
    }
  }
    validateQRCode(data);
    }, [data]);

  if (loadingScreenState === 'loading'){
    return(
    <section>
    <h1>Leyendo código...</h1>
    <div className="loading-container">
   <svg className="circle-svg" height="200" width="200">
     <circle cx="100" cy="100" r="50"></circle>
   </svg>
      </div>
      <button onClick = {()=> handleCloseView()}> Cerrar </button>
    </section>
    );
  }

  if(loadingScreenState === 'success'){
    playSuccess();
    return(
      <section>
        <h1>¡Exito!</h1>
    <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
    <h2>¡Bienvenide!</h2><br/>
    <h3>{codeData}</h3>
    <button onClick = {() => handleCloseView()}> Cerrar </button>
    <Spacer/> 
</section>);
  }

  if (loadingScreenState === 'error'){
    playError();
    return(
      <section>
      <h1>¡Error!</h1>
      <div className="circle">
          <div className="error"></div>
        </div>
        <h3>{codeData}</h3>
        <button onClick = {() => handleCloseView()}> Cerrar </button>
      </section>
      );
  }
}
}