import React from 'react';
import '../styles/LoginPage.css';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../utilities/useAuth";
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";


function LoginPage(){
    const { user, isLoading, signInWithGoogle, signInWithFacebook, signInWithTwitter } = useAuth();
    const navigate = useNavigate();
    // Confirm the link is a sign-in with email link.
    const auth = getAuth();
  if (isSignInWithEmailLink(auth, window.location.href)) {
    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before triggering
    // the sign-in operation.
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    let email = window.localStorage.getItem('emailForSignIn');
    if (!email) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again. For example:
      email = window.prompt('Please provide your email for confirmation');
    }
    // The client SDK will parse the code from the link for you.
    signInWithEmailLink(auth, email, window.location.href)
      .then((result) => {
        // Clear email from storage.
        window.localStorage.removeItem('emailForSignIn');
        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
      })
      .catch((error) => {
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
      });
  }
    if (isLoading) {
        return <div>Cargando datos...</div>;
    }
    if(user){
        navigate('/tickets');
    }

function goToEmailLogin(){
  navigate('/emailLoginPage');
}
    
 return(
        <section>
        <div className = "event-info-container">
        <p>Met Gala 2023 </p>
        <p>17 de noviembre 2023</p>
        <p>Milan 41</p>
        <p>Colonia Juárez</p>
        <p>Ciudad de México</p>
        <p>18:00</p>
        </div>
        <h1>Inicia tu sesión </h1>
        <button type="button" className ="login-with-google-btn" onClick={signInWithGoogle}>iniciar sesión con Google</button>
         {/* <button className="facebook btn" onClick={signInWithFacebook}>
         <div className="btn-icon fb-icon"></div>
        iniciar sesión con Facebook
      </button> */}
      <button className="twitter btn" onClick={signInWithTwitter}>
        <div className="twitter-icon btn-icon"></div>
        iniciar sesión con Twitter
      </button> 
      <button onClick={goToEmailLogin} type="button">
        iniciar sesión con e-mail
      </button>
        </section>
    );
}

export default LoginPage; 