// Import the functions you need from the SDKs you need
import {initializeApp}  from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCFtqPY1DYkhj-0j1nA8EFpUhQXy4LeeDg",
  authDomain: "metgala-563a6.firebaseapp.com",
  projectId: "metgala-563a6",
  storageBucket: "metgala-563a6.appspot.com",
  messagingSenderId: "910555712525",
  appId: "1:910555712525:web:ce9a53070b9696221d9944",
  measurementId: "G-NE5JJ2PYVX"
};

//var app = null;

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
auth.useDeviceLanguage(); 





 


