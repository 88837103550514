import React from 'react';
import './styles/App.css';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import TicketsPage from './pages/TicketsPage'
import BuyTicketsPage from './pages/BuyTicketsPage'
import OrderConfirmationPage from './pages/OrderConfirmationPage';
import OrderSuccessPage from './pages/OrderSuccessPage';
import AdminHomePage from './pages/AdminHomePage';
import ViewTicketsPage from './pages/ViewTicketsPage';
import Footer from './components/Footer';
import {BrowserRouter, Routes, Route } from "react-router-dom";
import PurchaseOrderDetailsPage  from './pages/PurchaseOrderDetailsPage'
import EmailLoginPage from './pages/EmailLoginPage';
import CreateNewEmailAccount from './pages/CreateNewEmailAccount';
import ResetPassword from './pages/ResetPassword';
import CodeReaderPage from './pages/CodeReaderPage';

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element = {<HomePage/>} />
      <Route path="/login" element = {<LoginPage/>} />
      <Route path="/tickets" element = {<TicketsPage/>} />
      <Route path="/buyTickets" element = {<BuyTicketsPage/>} />
      <Route path="/orderConfirmationPage" element = {<OrderConfirmationPage/>} />
      <Route path="/orderSuccessPage" element = {<OrderSuccessPage/>} />
      <Route path="/admin" element={<AdminHomePage/>} />
      <Route path="/viewTickets" element={<ViewTicketsPage/>} />
      <Route path="/purchaseOrderDetails" element={<PurchaseOrderDetailsPage/>} />
      <Route path="/emailLoginPage" element={<EmailLoginPage/>} />
      <Route path="/createNewAccount" element={<CreateNewEmailAccount/>} />
      <Route path="/resetPassword" element={<ResetPassword/>} />
      <Route path="/codeReader" element={<CodeReaderPage/>} />
    </Routes>

    <Footer/>
    </BrowserRouter>
  );
}

export default App;
