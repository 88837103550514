// @ts-nocheck
import React from 'react';
import { useAuth } from "../utilities/useAuth";

import {useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { viewAllPendingOrdersForUser } from '../utilities/FirebaseFunctions'
import '../styles/TicketsPage.css';
import sample from '../assets/WhatsAppButtonGreenLarge.png'
import Spacer from '../components/Spacer';


function TicketsPage(){
    const { user, signOut } = useAuth();
    const navigate = useNavigate();
    if (!user){
        navigate("/login")
    }
    function navigateToBuyTickets(){
        navigate("/buyTickets")
    }

    return(
        <section>
          
            <h1>¡Hola, {user?.displayName}! </h1>
        <h1>Mis Boletos</h1>
        <TicketsTable />
        <div>
        
        <p className="fine-text">* Los pagos se realizan a través de una transferencia bancaria. Si no cuentas con una cuenta bancaria, favor de hablarnos por WhatsApp o Instgram.</p>
        <button onClick={navigateToBuyTickets}>Apartar boletos</button>
        </div>
        <button onClick={signOut}>Cerrar Sesión </button>
        <p>¿Tienes dudas?</p>
        <a href = "https://wa.me/525524161721"  target="_blank" rel="noreferrer" >
          <img className = "whatsapp-button" src= {sample}/>
        </a>
        <Spacer/>
        </section>
    );
}

export default TicketsPage; 



function TicketsTable() {
  const { user } = useAuth();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  function showTickets(order){
   navigate('/viewTickets', {state:{order: order}});
  }

  function showPaymentInformation(order){
    navigate('/purchaseOrderDetails', {state:{order: order}});
  }

  useEffect(()=> {
    async function fetchTicketData() {
      setIsLoading(true);
      try {
        const response = await viewAllPendingOrdersForUser(user?.uid)
        setData(response);
      } catch (error) {
        console.error(error);
     } finally {
        setIsLoading(false);
     }
      }
      fetchTicketData();
    }, [user]);

    if (isLoading) {
      return <div style={{ color: 'black' }}>Loading...</div>;
    }
    if (data) {
      if (data.length === 0){
        return <p>Aún no tienes boletos</p>;
      }
      return <div style={{ color: 'black' }}>
        <table>
        <tr>
          <th>A nombre de: </th>
          <th>Cantidad de boletos: </th>
          <th>precio:</th>
          <th>estatus: </th>
          <th>numero de confirmación: </th>
          <th>acciones</th>
        </tr>
        {data.map(item => (<tr key={item.id}>
    <td>{item.alias}</td>
    <td>{item.tickets.length}</td>
    <td> {item.amountDue}</td>

    <td>{item.status}</td>
    <td>{item.confirmationNumber} </td>
    { item.status === "approved" ? 
    <td>      
          <button onClick = {()=>{showTickets(item)}}> Ver Boleto(s) </button>
    </td> : <td>
          <button onClick = {()=>{showPaymentInformation(item)}}> Ver Detalles de Pago </button>
    </td>
    
    }
  </tr> 
      ))}
      </table>
      </div>;
    }
    return <div style={{ color: 'black' }}>Sin data</div>;
    }