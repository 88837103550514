export class User{
    constructor (name, email){
      this.name = name
      this.email = email 
    }
  }
  export class Order{
    constructor (confirmationNumber,userID,alias, email, tickets, expirationDate, status, dateCreated, promoCode, amountDue){
      this.confirmationNumber = confirmationNumber
      this.userID = userID
      this.alias = alias
      this.email = email
      this.tickets = tickets
      this.expirationDate = expirationDate
      this.status = status
      this.dateCreated = dateCreated
      this.promoCode = promoCode 
      this.amountDue = amountDue
    } 
  }
  
  export class Ticket {
    constructor (ticketID, userID, orderID, ticketType, amountPaid, promoApplied, dateCreated, status, houseName, categories){
      this.ticketID = ticketID
      this.userID = userID
      this.orderID = orderID
      this.ticketType = ticketType
      this.amountPaid = amountPaid
      this.promoApplied = promoApplied
      this.dateCreated = dateCreated
      this.status = status
      this.houseName = houseName
      this.categories = categories
    }
  }
  
  
  export class PromoCode{
    constructor (promoCode, discountAmount, numberOfUses,allowedNumberOfUses, expirationDate){
      this.promoCode = promoCode
      this.discountAmount = discountAmount
      this.numberOfUses = numberOfUses
      this.allowedNumberOfUses = allowedNumberOfUses
      this.expirationDate = expirationDate
    }
  }
  
  