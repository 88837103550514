import { app } from '../Firebase'
import { getFirestore, increment, doc, getDoc, setDoc, Timestamp, addDoc, serverTimestamp, collection, query, where, getDocs, updateDoc, FieldValue } from "firebase/firestore";
import {User, PromoCode, Order, Ticket} from "../models/Models"

const db = getFirestore(app);

// Firebase functions 
function findUser(userID){

}

function createUser(email){

}

// USER
const userConverter = {
    toFirestore: (user) => {
        return {
            name: user.name,
            email: user.email,
            };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new User(data.name, data.email);
    }
}

// ORDERS 
const orderConverter = {
    toFirestore: (order) => {
        return {
            confirmationNumber: order.confirmationNumber,
            userID: order.userID,
            alias: order.alias,
            email: order.email,
            tickets: order.tickets,
            expirationDate: order.expirationDate,
            status: order.status,
            dateCreated: order.dateCreated,
            promoCode:  order.promoCode,
            amountDue: order.amountDue
        };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new Order(snapshot.id, data.userID, data.alias, data.email, data.tickets, data.expirationDate.toDate(), data.status, data.dateCreated.toDate(), data.promoCode, data.amountDue);
    }
}

export async function createPendingOrder(order){

    // make sure doc doesn't already exist
    const docRef = doc(db, "orders", order.confirmationNumber);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      alert("This order number already exists, please try again");
    } else {
    const ref = doc(db, 'orders', order.confirmationNumber).withConverter(orderConverter);
    await setDoc(ref,order)
}
}


export async function viewAllPendingOrdersForUser(userID){
    var output = [];
    const ordersQuery = query(collection(db, "orders"), where("userID", "==", userID)).withConverter(orderConverter);
    const querySnapshot = await getDocs(ordersQuery);
    querySnapshot.forEach((doc) => {
        output.push(doc.data())
        console.log(doc.id, " => ", doc.data());
      });
    return output
}

// TICKETS
export const ticketConverter = {
    toFirestore: (ticket) => {
        return {
            ownerID: ticket.userID,
            ticketType: ticket.ticketType,
            amountPaid: ticket.amountPaid,
            promoApplied: ticket.promoApplied,
            dateCreated: ticket.dateCreated,
            status: ticket.status,
            orderID: ticket.orderID,
            ticketID: ticket.ticketID,
            userID: ticket.userID,
            houseName: ticket.houseName,
            categories: ticket.categories
        };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new Ticket(snapshot.id, data.userID, data.orderID, data.ticketType, data.amountPaid, data.promoApplied, data.dateCreated.toDate(), data.status, data.houseName, data.categories);
    }
}

export async function viewAllTicketsForOrder(orderID){
    var output = [];
    const ordersQuery = query(collection(db, "tickets"), where("orderID", "==", orderID)).withConverter(ticketConverter);
    const querySnapshot = await getDocs(ordersQuery);
    querySnapshot.forEach((doc) => {
        output.push(doc.data())
        console.log(doc.id, " => ", doc.data());
      });
    return output
}

export async function createPendingTicket(ticket){
    const docRef = doc(db, 'tickets', ticket.ticketID).withConverter(ticketConverter);
    await setDoc(docRef, ticket)
}

// PROMO CODES
const promoConverter = {
    toFirestore: (promoCode) => {
        return {
            promoCode: promoCode.promoCode,
            discountAmount: promoCode.discountAmount,
            numberOfUses: promoCode.numberOfUses,
            allowedNumberOfUses: promoCode.allowedNumberOfUses,
            expirationDate: new Date(promoCode.expirationDate)
        };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new PromoCode(snapshot.id, data.discountAmount, data.numberOfUses, data.allowedNumberOfUses, data.expirationDate.toDate());
    }
}

export async function validatePromoCode(promoCode){
    const docRef = doc(db, 'promoCodes', promoCode).withConverter(promoConverter);
    const docSnap = await getDoc(docRef);
    var output = [0, "Este promo no es valido."]
    if (docSnap.exists()) {
        const promo = docSnap.data()
        if(promo.allowedNumberOfUses === promo.numberOfUses){
            output =  [0, "El uso de este promo ya se agotó."]; 
        } else if (promo.expirationDate < new Date()){
            output = [0, "Este promo ya se venció"];
        } else {
            output =  [promo.discountAmount, "El promo es valido!"]
        }
    }
    return output 
}

//////////////////////////////////////////////////////////////////////////////////
  ////////////////// ADMIN ONLY FUNCTIONS ////////////////

export async function createPromoCode(promoCode, discountAmount, allowedNumberOfUses, expirationDate){
    const docRef = doc(db, 'promoCodes', promoCode);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        alert("Ya existe este promo code");
    } else {
        const ref = doc(db, 'promoCodes', promoCode).withConverter(promoConverter);
        await setDoc(ref, new PromoCode(promoCode, discountAmount, 0 , allowedNumberOfUses, expirationDate));
    }
}


export async function calculateApprovedTicketSales(){
    var total = 0.0
    const ticketsQuery = query(collection(db, "tickets")).withConverter(ticketConverter);
    const querySnapshot = await getDocs(ticketsQuery);
    querySnapshot.forEach((doc)=> {
        const ticket = doc.data()
        if(ticket.status === "approved"){
            total = total + parseFloat(ticket.amountPaid)
        }
    })
    return total 
}

export async function calculatePendingTicketSales(){
    var total = 0.0
    const ticketsQuery = query(collection(db, "tickets")).withConverter(ticketConverter);
    const querySnapshot = await getDocs(ticketsQuery);
    querySnapshot.forEach((doc)=> {
        const ticket = doc.data()
        if(ticket.status === "pending"){
            total = total + parseFloat(ticket.amountPaid)
        }
    })
    return total 
}

export async function getAllOrders(){
        var output = [];
        const ordersQuery = query(collection(db, "orders")).withConverter(orderConverter);
        const querySnapshot = await getDocs(ordersQuery);
        querySnapshot.forEach((doc) => {
            output.push(doc.data())
          });
        return output
    }

    export async function getAllPromos(){
        var output = [];
        const promosQuery = query(collection(db, "promoCodes")).withConverter(promoConverter);
        const querySnapshot = await getDocs(promosQuery);
        querySnapshot.forEach((doc) => {
            output.push(doc.data())
          });
        return output
    }

    export async function getAllTickets(){
        var output = [];
        const ticketsQuery = query(collection(db, "tickets")).withConverter(ticketConverter);
        const querySnapshot = await getDocs(ticketsQuery);
        querySnapshot.forEach((doc) => {
            output.push(doc.data())
          });
        return output
    }
    

export async function approveOrder(order){
    const docRef = doc(db, 'orders', order.confirmationNumber).withConverter(orderConverter);
    await updateDoc(docRef, {
        status: "approved"
    });
    sendTicketsToUser(order.email)
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        const order = docSnap.data()
        let ticketIDs = order.tickets
        for (const ticketID of ticketIDs){
        await validateTicket(ticketID)
        await incrementPromoCodeUse(order)
    }
    }
}

async function incrementPromoCodeUse(order){

    if(order.promoCode.length > 1){
        const promoRef =  doc(db, 'promoCodes', order.promoCode).withConverter(promoConverter);
        await updateDoc(promoRef, { numberOfUses: increment(1) });
    }
}

export async function rejectOrder(order){
    const docRef = doc(db, 'orders', order.confirmationNumber).withConverter(orderConverter);
    await updateDoc(docRef, {
        status: "rejected"
    });
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        const order = docSnap.data()
        let ticketIDs = order.tickets
        for (const ticketID of ticketIDs){
            await invalidateTicket(ticketID);
        }
    }
}

export async function validateTicket(ticketID){
    const docRef = doc(db, 'tickets', ticketID).withConverter(ticketConverter);
    await updateDoc(docRef, {
        status: "approved"
    });
}

export async function invalidateTicket(ticketID){
    const docRef = doc(db, 'tickets', ticketID).withConverter(ticketConverter);
    await updateDoc(docRef, {
        status: "rejected"
    });

}




async function sendTicketsToUser(userEmail){
    const htmlData = `<div>
    <h1>¡Hola de nuevo! </h1>
    
    <p>Gracias por comprar boleto(s) para el Met Gala.</p>
    
    <p>Ya estan listos tus boletos. Por favor visita la página de la <a href="https://metgala.houseofmagdalena.com"> Met Gala 2023</a> para ver tus boletos.</p>
    <p>Te recomendamos que guardes una captura de pantalla del código QR para facilitar el aceso al evento.</p>
    
    <p>Como siempre, estamos aquí para ayudarte. Envíanos un <a href = "https://wa.me/525524161721"  target="_blank" >WhatsApp</a> o mensage por <a href="https://www.instagram.com/houseofmagdalena/">Instagram</a> con cualquier duda o inquetitud.</p>
    
    <p>Muchas gracias,</p>
    
    <p>Avalencha</p>
    <p>House of Magdalena</p>
    
    </div>`

    const textData = "Gracias por comprar boleto(s) para el Met Gala. Ya estan listos tus boletos. Por favor visita la página de la Met Gala 2023 para ver tus boletos."
    const docRef = await addDoc(collection(db, 'mail'), {
        to: [userEmail],
        message: {
            subject: 'Met Gala 2023 - Tus boletos están listos',
            text: textData,
            html: htmlData 
        }
    }
)
}









export async function sendOrderConfirmation(order){
    const textData = "Gracias por partar boleto(s) para el Met Gala 2023.  Favor de realizar una tranferencia y te enviaremos tus boletos lo mas pronto posible."
    const htmlData = `<div>
    <h1>Hola ${order.alias}, </h1>
    
    <p>Gracias por apartar boleto(s) para el Met Gala.</p>
    
    <p>Por favor, realiza una transferencia con los datos correspondientes: </p>
    
    <p>A nombre de: </p>
    <p><b>Sergio Andrés Tapia Duarte</b></p>

    <p>Monto: </p>
    <p>${order.amountDue}</p>
    
    <p>Banco: </p>
    <p><b>BanRegio</b></p>
    
    <p>CLABE: </p>
    <p><b>058597000013299509</b></p>
    
    <p>Numero de cuenta: </p>
    <p><b>995149060016</b></p>
    
    <p>Numero de tarjeta: </p>
    <p><b>4741745775954650</b></p>
    
    <p>Concepto: </p>
    <p><b>${order.confirmationNumber}</b></p>
    
    <p>Estamos aquí para ayudarte. Envíanos un <a href = "https://wa.me/525524161721"  target="_blank" >WhatsApp</a> o mensage por <a href="https://www.instagram.com/houseofmagdalena/">Instagram</a> con cualquier duda o inquetitud.</p>
    
    <p>Muchas gracias,</p>
    
    <p>Avalencha</p>
    <p>House of Magdalena</p>
    
    </div>`
    const docRef = await addDoc(collection(db, 'mail'), {
        to: [order.email],
        message: {
            subject: 'Met Gala 2023 - Confirmación de Pedido',
            text: textData,
            html: htmlData  
        }
    }
)
}
