import React from 'react';
import {useNavigate} from 'react-router-dom';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useState } from 'react';
import toast, { Toaster } from "react-hot-toast";



export default function ResetPassword() {

    const [email, setEmail] = useState('')
    const auth = getAuth();
    const navigate = useNavigate();
  
    const triggerResetEmail = async () => {
      await sendPasswordResetEmail(auth, email);
      toast.success("Password reset email sent")
    }
   
    return (
    <section>
              <Toaster/>
              <form>
            <label> correo electrónico <br/>
        <input type="text"
         value={email}
         onChange={(e) => setEmail(e.target.value)} 
         />
         <br/>
        </label>
        
        </form>        
        <button  onClick={triggerResetEmail}>Cambiar contraseña</button>
        <button  onClick={()=>navigate('/')}>Regresar al inicio</button>

      </section>
    )
  }
  