import React from "react";
import { useAuth } from "../utilities/useAuth";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState} from "react";
import { getAllOrders, getAllTickets, approveOrder, rejectOrder, calculateApprovedTicketSales, calculatePendingTicketSales, getAllPromos } from "../utilities/FirebaseFunctions";
import PromoCodeCreator from "./PromoCodeCreator";
import Spacer from "../components/Spacer";
import { currencyFormat } from "../utilities/Utilities";


export default function AdminHomePage(){
    const { user, isLoading, signInWithGoogle } = useAuth();
    const navigate = useNavigate();

    if (isLoading) {
        return <div>Loading Data..</div>;
    }
    if(user){
        let permissions = require('../utilities/ElevatedPermissions.json');
        if(!permissions.administrator.includes(user.email)){
                        navigate("/");
        } else {
            return (
                <section>
                    <h1>Admin Home Page</h1>
                    <p>{user.displayName}</p>
                    <SalesReport/>
                    <OrdersTable/>
                    <TicketsTable/>
                    <PromosTable/> 
                    <PromoCodeCreator/>
                    <Spacer/>
                    <Spacer/>
                </section>
            );
        }
    }
    return(
        <div>
        <h1>Admin Login Page</h1>
        <button type="button" className ="login-with-google-btn" onClick={signInWithGoogle}>iniciar sesión con Google</button>
        </div>
    );
    
}


function OrdersTable() {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);
    const navigate = useNavigate(); 
    async function handleOrderApproval(order){
        await approveOrder(order)
        navigate(0);
    }
    async function handleOrderRejection(order){
      await rejectOrder(order)
      navigate(0);
  }

    useEffect(()=> {
        async function fetchOrderData() {
          setIsLoading(true);
          try {
            const response = await getAllOrders()
            setData(response);
          } catch (error) {
            console.error(error);
         } finally {
            setIsLoading(false);
         }
          }
          fetchOrderData();
        }, []);
        
    if (isLoading) {
      return <div style={{ color: 'black' }}>Loading...</div>;
    }
    if (data) {
    
        return (
            <div>
            <h2>Pedidos Por Confirmar </h2>
          
            <table>
        <tr>
          <th>A nombre de: </th>
          <th>email: </th>
          <th>Cantidad de boletos: </th>
          <th>precio:</th>
          <th>estatus: </th>
          <th>numero de confirmación: </th>
          <th>Promo: </th>
          <th>Fecha de creación:</th>
          <th>Fecha de vencimiento de pago:</th>
          <th>acciones</th>
        </tr>

        {data.filter((item)=> item.status === "pending").map(item => (<tr key={item.confirmationNumber}>
            <td>{item.alias}</td>
            <td>{item.email}</td>
            <td>{item.tickets.length}</td>
            <td> {item.amountDue}</td>
             <td>{item.status}</td>
             <td>{item.confirmationNumber} </td>
             <td>{item.promoCode} </td>
             <td>{item.dateCreated.toString()}</td>
             <td>{item.expirationDate.toString()}</td>

             <button onClick={() => { if (window.confirm('¿Estas segura que quieres aprobar este pedido?')) handleOrderApproval(item) }} > Aprobar Orden</button>
             <button onClick={() => { if (window.confirm('¿Estas segura que quieres rechazar este pedido?')) handleOrderRejection(item) }} > Rechazar Orden</button>

    </tr> 
        ))}
    </table> 


            <h2>Pedidos Aprobados </h2>
          
            <table>
        <tr>
          <th>A nombre de: </th>
          <th>Email: </th>
          <th>Cantidad de boletos: </th>
          <th>precio:</th>
          <th>estatus: </th>
          <th>numero de confirmación: </th>
          <th>acciones</th>
        </tr>

        {data.filter((item)=> item.status === "approved").map(item => (<tr key={item.id}>
            <td>{item.alias}</td>
            <td>{item.email}</td>
            <td>{item.tickets.length}</td>
            <td> {item.amountDue}</td>

             <td>{item.status}</td>
             <td>{item.confirmationNumber} </td>
    </tr> 
        ))}
    </table> 

    <h2>Pedidos Rechazados </h2>
          
          <table>
      <tr>
        <th>A nombre de: </th>
        <th>Cantidad de boletos: </th>
        <th>precio:</th>
        <th>estatus: </th>
        <th>numero de confirmación: </th>
        <th>acciones</th>
      </tr>

      {data.filter((item)=> item.status === "rejected").map(item => (<tr key={item.id}>
          <td>{item.alias}</td>
          <td>{item.tickets.length}</td>
          <td> {item.amountDue}</td>

           <td>{item.status}</td>
           <td>{item.confirmationNumber} </td>
           <td>        
</td>
  </tr> 
      ))}
  </table> 





    </div>
        );
}
}

function TicketsTable() {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(null);


    useEffect(()=> {
        async function fetchTicketData() {
          setIsLoading(true);
          try {
            const response = await getAllTickets()
            setData(response);
          } catch (error) {
            console.error(error);
         } finally {
            setIsLoading(false);
         }
          }
          fetchTicketData();
        }, []);
        
    if (isLoading) {
      return <div style={{ color: 'black' }}>Loading...</div>;
    }
    if (data) {
        return (
            <div>
                        <h2>Boletos en Total: ({data.length}) </h2>


<div>
            <h2>Boletos Aprobados  </h2>
          
            <table>
        <tr>
          <th>User ID: </th>
          <th>Ticket ID: </th>
          <th>Tipo de Boleto:</th>
          <th>numero de orden: </th>
          <th>promo: </th>
          <th>fecha de vencimiento: </th>
          <th>estatus: </th>
          <th>casa: </th>
          <th>categoría: </th>
        </tr>

        {data.filter((item)=> item.status === "approved").map(item => (<tr key={item.id}>
            <td>{item.userID}</td>
            <td>{item.ticketID}</td>
            <td>{item.ticketType}</td>
            <td>{item.orderID}</td>
            <td>{item.promoApplied}</td>
            <td>{item.dateCreated.toString()}</td>
            <td>{item.status}</td>
            {item.houseName === "" ? <td></td> : <td>{item.houseName}</td>}
            <td>{item.categories}</td>

    </tr> 
        ))}
    </table> 
    </div>

              <div>
            <h2>Boletos Pendientes </h2>
          
            <table>
        <tr>
          <th>User ID: </th>
          <th>Ticket ID: </th>
          <th>Tipo de Boleto:</th>
          <th>Monto: </th>
          <th>numero de orden: </th>
          <th>promo: </th>
          <th>fecha de vencimiento: </th>
          <th>estatus: </th>
          <th>casa: </th>
          <th>categoría: </th>
        </tr>

        {data.filter((item)=> item.status === "pending").map(item => (<tr key={item.id}>
            <td>{item.userID}</td>
            <td>{item.ticketID}</td>
            <td>{item.ticketType}</td>
            <td> {item.amountPaid}</td>
            <td>{item.orderID}</td>
            <td>{item.promoApplied}</td>
            <td>{item.dateCreated.toString()}</td>
            <td>{item.status}</td>
            {item.houseName === "" ? <td></td> : <td>{item.houseName}</td>}
            <td>{item.categories}</td>

    </tr> 
        ))}
    </table> 
    </div>
    <div>
            <h2>Boletos Rechazados </h2>
          
            <table>
        <tr>
          <th>User ID: </th>
          <th>Ticket ID: </th>
          <th>Tipo de Boleto:</th>
          <th>Monto: </th>
          <th>numero de orden: </th>
          <th>promo: </th>
          <th>fecha de vencimiento: </th>
          <th>estatus: </th>
          <th>casa: </th>
          <th>categoría: </th>
        </tr>

        {data.filter((item)=> item.status === "rejected").map(item => (<tr key={item.id}>
            <td>{item.userID}</td>
            <td>{item.ticketID}</td>
            <td>{item.ticketType}</td>
            <td> {item.amountPaid}</td>
            <td>{item.orderID}</td>
            <td>{item.promoApplied}</td>
            <td>{item.dateCreated.toString()}</td>
            <td>{item.status}</td>
            {item.houseName === "" ? <td></td> : <td>{item.houseName}</td>}
            <td>{item.categories}</td>

    </tr> 
        ))}
    </table> 
    </div>



    </div>
        );
}

}



function SalesReport(){
  const [isLoadingApprovedSales, setIsLoadingApprovedSales] = useState(false);
  const [approvedTotal, setApprovedTotal] = useState(0);

  const [isLoadingPendingSales, setIsLoadingPendingSales] = useState(false);
  const [pendingTotal, setPendingTotal] = useState(0);


  useEffect(()=> {
    async function fetchTotalApprovedSales() {
      setIsLoadingApprovedSales(true);
      try {
        const response = await calculateApprovedTicketSales()
        setApprovedTotal(response);
      } catch (error) {
        console.error(error);
     } finally {
      setIsLoadingApprovedSales(false);
     }
      }
      fetchTotalApprovedSales();
    }, []);

    useEffect(()=> {
      async function fetchTotalPendingSales() {
        setIsLoadingPendingSales(true);
        try {
          const response = await calculatePendingTicketSales()
          setPendingTotal(response);
        } catch (error) {
          console.error(error);
       } finally {
        setIsLoadingPendingSales(false);
       }
        }
        fetchTotalPendingSales();
      }, []);

      return(
        <div>
        <h1>Ventas Aprobadas: </h1>
        {isLoadingApprovedSales ? <p>Loading...</p> : <p><b>{currencyFormat(approvedTotal)}</b></p>}

        <h1>Ventas Pendientes: </h1>
        {isLoadingPendingSales ? <p>Loading...</p> : <p>{currencyFormat(pendingTotal)}</p>}

        </div>


      )
  




}

function PromosTable(){
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);


  useEffect(()=> {
      async function fetchPromoData() {
        setIsLoading(true);
        try {
          const response = await getAllPromos()
          setData(response);
        } catch (error) {
          console.error(error);
       } finally {
          setIsLoading(false);
       }
        }
        fetchPromoData();
      }, []);
      
  if (isLoading) {
    return <div style={{ color: 'black' }}>Loading...</div>;
  }
  if (data) {
      return (
          <div>
                      <h2>Promos en Total: ({data.length}) </h2>


<div>
          
        
          <table>
      <tr>
        <th>PROMO: </th>
        <th> PORCENTAJE DE DESCUENTO: </th>
        <th> NÚMERO DE USOS:</th>
        <th> MÁXMIO NUMERO DE USOS: </th>
        <th> FECHA DE EXPIRACIÓN: </th>

      </tr>

      {data.sort((item1, item2)=> item1.numberOfUses < item2.numberOfUses).map(item => (<tr key={item.id}>
          <td>{item.promoCode}</td>
          <td>{item.discountAmount * 100} %</td>
          <td>{item.numberOfUses}</td>
          <td> {item.allowedNumberOfUses}</td>
          <td>{item.expirationDate.toString()}</td>
  </tr> 
      ))}
  </table> 
  </div>
  </div> 
      );
}

}