import React from "react";
import toast, { Toaster } from "react-hot-toast";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";




export default function CreateNewEmailAccount() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const navigate = useNavigate();

    function handleCreateAccount(){
        const auth = getAuth();
        createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed up 
            const user = userCredential.user;
            toast.success("¡Tu cuenta fue creada con éxito!")
            navigate('/tickets')
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            toast.error(errorMessage)
        });

    }

    return(
        <section>
            <Toaster/> 
            <form>

            <label>tu nombre, alias, o aka: <br/>
                    <input type="text"
                value={name}
                onChange={(e) => setName(e.target.value)} 
                />
                <br/>
                </label>

                <label> correo electrónico: <br/>
                    <input type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)} 
                />
                <br/>
                </label>
        <label> contraseña:
        <input type="password"
         value={password}
         onChange={(e) => setPassword(e.target.value)} 
         />
         <br/>
        </label>

        <label> confirma tu contraseña:
        <input type="password"
         value={confirmPassword}
         onChange={(e) => setConfirmPassword(e.target.value)} 
         />
         <br/>
        </label>

            </form>
            <button onClick = {handleCreateAccount}>Crear cuenta</button>


        </section>
    )
}