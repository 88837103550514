import { auth }  from "../Firebase";
import { useEffect, useState } from "react";
import { GoogleAuthProvider, signInWithPopup, TwitterAuthProvider, FacebookAuthProvider } from "firebase/auth";


export const useAuth = () => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleUser = (user) => {
    if (user) {
      setUser(user);
    } else {
      setUser(null);
    }
    setIsLoading(false);
  };
  const googleProvider = new GoogleAuthProvider();
  const twitterProvider = new TwitterAuthProvider();
  const facebookProvider = new FacebookAuthProvider();
  
  const signInWithTwitter = () => {
    signInWithPopup(auth, twitterProvider)
    .then((result) => handleUser(result.user))
      .catch((error) => console.log(error));
  }

  const signInWithGoogle = () => {
    signInWithPopup(auth, googleProvider)
      .then((result) => handleUser(result.user))
      .catch((error) => console.log(error));
  };

  const signInWithFacebook = () => {
    signInWithPopup(auth, facebookProvider)
      .then((result) => handleUser(result.user))
      .catch((error) => console.log(error));
  };

  const signOut = () => {
    auth
      .signOut()
      .then(() => handleUser(null));
  };

  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged(handleUser);
    return () => unsubscribe();
  }, []);

  return { user, isLoading, signInWithGoogle,signInWithFacebook, signInWithTwitter, signOut };
};
